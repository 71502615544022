import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { addMinutes } from 'date-fns';
import { State, namespace } from 'vuex-class';
import FullscreenDialog from '@/components/templates/FullscreenDialog.vue';
import Loading from '@/components/atoms/Loading.vue';
import { formatDateString } from '@/date';
import { API_BOOKING_OFFSET_TIME } from '@/env';
import EventHelper from '@/helpers/event';
import { TenantDetails } from '@/store/types/modules';
import { UiFacility } from '@/models/facility';
import { displayError, displaySuccess } from '@/helpers/message';
import FacilityHelper from '@/helpers/facility';
import ChairIcon from '@/assets/images/chair-icon.svg';
import ArrowRight from '@/assets/images/arrow-right.svg';
import Clock from '@/assets/images/clock.svg';

const facilityView = namespace('facilityView');

@Component({
  components: { FullscreenDialog, Loading, ChairIcon, ArrowRight, Clock },
})
export default class FacilityBookConfirmation extends Vue {
  @Prop({ type: Boolean }) show!: boolean;

  @Prop({ type: Number }) minutes!: number;

  @Prop({ type: Date }) now!: Date;

  @State('tenant') tenant!: TenantDetails;

  @facilityView.State('facility') facility!: UiFacility;

  @facilityView.Mutation('setFacility') setFacility!: (f: UiFacility) => void;

  @facilityView.Mutation('setCachedFacility') setCachedFacility!: (f: UiFacility) => void;

  isLoading = false;

  get formattedEndTime(): string {
    return formatDateString(this.endTime.toString(), 'HH:mm');
  }

  get formattedStartTime(): string {
    return formatDateString(this.startTime.toString(), 'HH:mm');
  }

  get endTime(): Date {
    return addMinutes(this.now, this.minutes + API_BOOKING_OFFSET_TIME / 1000 / 60);
  }

  get startTime(): Date {
    return addMinutes(this.now, API_BOOKING_OFFSET_TIME / 1000 / 60);
  }

  get eventHelper(): EventHelper {
    return new EventHelper(this);
  }

  get facilityHelper(): FacilityHelper {
    return new FacilityHelper(this);
  }

  get tenantId(): string {
    return this.tenant.current.id;
  }

  get facilityCapacity(): string {
    return this.facilityHelper.capacity(this.facility);
  }

  async bookingHandler(): Promise<void> {
    this.isLoading = true;
    try {
      const updatedFacility = await this.eventHelper.createFacilityEvent(
        this.tenantId,
        this.facility,
        this.minutes,
      );

      this.setCachedFacility(updatedFacility);
      this.setFacility(updatedFacility);

      displaySuccess(
        this.$gettextInterpolate(this.$gettext('%{facilityName} has been booked'), {
          facilityName: this.facilityHelper.facilityName(this.facility),
        }),
      );
      this.$emit('close');
    } catch (e) {
      displayError(
        this.$gettextInterpolate(this.$gettext('Failed to book %{facilityName}'), {
          facilityName: this.facilityHelper.facilityName(this.facility),
        }),
      );
    }
    this.isLoading = false;
  }
}
