/**
 * Event component helper
 *
 * @desc helps do CRUD in components for faciliy events
 */
import { Store } from 'vuex';

import logger from '@/logger';
import { formatDateString } from '@/date';
import { Event } from '@/api/models';
import { EventApi, PostEventRequest, DeleteEventRequest, ShortenCalendarEventRequest } from '@/api/apis/EventApi';
import apiConfiguration from '@/helpers/config';
import { authMiddleware } from '@/helpers/authToken';
import TenantHelper from '@/helpers/tenant';
import { displayWarn } from '@/helpers/message';
import { UiFacility, FacilityStatusNameEnum } from '@/models/facility';
import { AuthToken , Idp , TenantDetails , BookedFacility } from '@/store/types/modules';
import { FlashMessage } from '@/store/flashMessage';
import store from '@/store';
import { isCustomFacility } from './facility';

interface EventHelperDeps {
  $gettext: (msgid: string) => string;
  $store: Store<{
    authToken: AuthToken;
    flashMessage: FlashMessage;
    idp: Idp;
    tenant: TenantDetails;
    bookedFacility: BookedFacility;
  }>;
}

interface EventHelperAssists {
  tenant: TenantHelper;
}

export const bookingEventOnCustomFacilityError = "Event booking is not supported for custom facilities";

export default class EventHelper {
  vue: EventHelperDeps;

  helper: EventHelperAssists;

  constructor(deps: EventHelperDeps) {
    this.vue = deps;
    this.helper = {
      tenant: new TenantHelper(deps),
    };
  }

  private eventTime(
    event: Event | undefined,
    timeType: 'start_time' | 'end_time',
    formattingRule: string,
  ): string {
    const unknown = this.vue.$gettext('Unknown Time');
    if (!event || !event[timeType]) {
      return unknown;
    }
    return formatDateString(event[timeType].toString(), formattingRule);
  }

  eventStartTime(facility: UiFacility, formattingRule: string): string {
    const { event } = facility;
    return this.eventTime(event, 'start_time', formattingRule);
  }

  eventEndTime(facility: UiFacility, formattingRule: string): string {
    const { event } = facility;
    return this.eventTime(event, 'end_time', formattingRule);
  }

  nextEventStartTime(facility: UiFacility, formattingRule: string): string {
    const nextEvent = facility.next_event;
    return this.eventTime(nextEvent, 'start_time', formattingRule);
  }

  nextEventEndTime(facility: UiFacility, formattingRule: string): string {
    const nextEvent = facility.next_event;
    return this.eventTime(nextEvent, 'end_time', formattingRule);
  }

  eventSummary(facility: UiFacility): string {
    const { event } = facility;
    const unknown = this.vue.$gettext('Unknown Event');
    if (!event || !event.summary) {
      return unknown;
    }
    return event.summary;
  }

  nextEventInMinutes(facility: UiFacility, currentDate: Date): number {
    const currentMinutes = currentDate.getTime() / 1000 / 60;
    const nextEvent = facility.next_event;

    if (!nextEvent) {
      return currentMinutes;
    }

    if (!nextEvent.start_time) {
      return currentMinutes;
    }

    const nextStartTimeInMuties = nextEvent.start_time.getTime() / 1000 / 60;

    return nextStartTimeInMuties - currentMinutes;
  }

  storeNewEvent(
    tenantId: string,
    facility: UiFacility,
    newEvent: Event
  ): UiFacility {
    const now = new Date();
    const updatedFacility = {
      ...facility,
      ...{ event: newEvent, status_name: FacilityStatusNameEnum.Occupied, updated: now },
    };
    store.commit('addBookedFacility', { tenantId, facility: updatedFacility });

    logger.info('Facility has been booked', { ...updatedFacility });
    return updatedFacility;
  }

  async createFacilityEvent(
    tenantId: string,
    facility: UiFacility,
    durationInMinutes: number,
  ): Promise<UiFacility> {
    if (isCustomFacility(facility)) {
      throw new Error(bookingEventOnCustomFacilityError);
    }

    const body = { duration_in_min: durationInMinutes };
    const reqParams = {
      tenantId,
      calendarIntegrationId: facility.calendar_integration_id,
      calendarId: facility.calendar_id,
      createEventRequest: body,
    } as PostEventRequest;

    logger.info('Facility is booking...', reqParams);

    return new EventApi(apiConfiguration)
      .withMiddleware(authMiddleware)
      .postEvent(reqParams)
      .then(newEvent => this.storeNewEvent(tenantId, facility, newEvent))
      .catch(e => { throw e; });
  }

  async deleteFacilityEvent(tenantId: string, facility: UiFacility): Promise<UiFacility> {
    if (isCustomFacility(facility)) {
      logger.warn("Attempted to delete an event on a custom facility", { ...facility });
      throw new Error("Event deletion is not supported for custom facilities");
    }

    if (!facility.event || !facility.event.id) {
      displayWarn(this.vue.$gettext('Calendar event is not found'));
      logger.warn('Calendar event is not found', { ...facility });
      throw new Error('Calendar event is not found');
    }

    const reqParams = {
      tenantId,
      calendarIntegrationId: facility.calendar_integration_id,
      calendarId: facility.calendar_id,
      eventId: facility.event.id,
    } as DeleteEventRequest;

    logger.info('Booked facility is releasing...', reqParams);

    await new EventApi(apiConfiguration)
      .withMiddleware(authMiddleware)
      .deleteEventRaw(reqParams);

    const now = new Date();
    const updatedFacility = {
      ...facility,
      ...{ status_name: FacilityStatusNameEnum.Vacant, updated: now },
    };
    delete updatedFacility.event;
    store.commit('deleteBookedFacility', { tenantId, facilityId: facility.facility_id });

    logger.info('Booked facility has been canceled', { ...updatedFacility });

    return updatedFacility;
  }

  async shortenFacilityEvent(reqParams: ShortenCalendarEventRequest): Promise<void> {
    logger.info(`Shortening event ${reqParams.eventId}`);
    await new EventApi(apiConfiguration)
      .withMiddleware(authMiddleware)
      .shortenCalendarEvent(reqParams);
  }
}
