/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateEventRequest,
    CreateEventRequestFromJSON,
    CreateEventRequestToJSON,
    Event,
    EventFromJSON,
    EventToJSON,
    ExtendEventRequest,
    ExtendEventRequestFromJSON,
    ExtendEventRequestToJSON,
    FacilityEvent,
    FacilityEventFromJSON,
    FacilityEventToJSON,
} from '../models';

export interface DeleteEventRequest {
    tenantId: string;
    calendarIntegrationId: string;
    calendarId: string;
    eventId: string;
}

export interface ExtendCalendarEventRequest {
    tenantId: string;
    calendarIntegrationId: string;
    calendarId: string;
    eventId: string;
    extendEventRequest: ExtendEventRequest;
}

export interface GetTenantEventsRequest {
    tenantId: string;
    startTime: Date;
    endTime: Date;
    facilityId?: string;
    activeFacilityOnly?: boolean;
}

export interface PostEventRequest {
    tenantId: string;
    calendarIntegrationId: string;
    calendarId: string;
    createEventRequest: CreateEventRequest;
}

export interface ShortenCalendarEventRequest {
    tenantId: string;
    calendarIntegrationId: string;
    calendarId: string;
    eventId: string;
}

/**
 * 
 */
export class EventApi extends runtime.BaseAPI {

    /**
     * Delete a calendar event
     * Delete a calendar event
     */
    async deleteEventRaw(requestParameters: DeleteEventRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling deleteEvent.');
        }

        if (requestParameters.calendarIntegrationId === null || requestParameters.calendarIntegrationId === undefined) {
            throw new runtime.RequiredError('calendarIntegrationId','Required parameter requestParameters.calendarIntegrationId was null or undefined when calling deleteEvent.');
        }

        if (requestParameters.calendarId === null || requestParameters.calendarId === undefined) {
            throw new runtime.RequiredError('calendarId','Required parameter requestParameters.calendarId was null or undefined when calling deleteEvent.');
        }

        if (requestParameters.eventId === null || requestParameters.eventId === undefined) {
            throw new runtime.RequiredError('eventId','Required parameter requestParameters.eventId was null or undefined when calling deleteEvent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/{tenant_id}/calendar_integrations/{calendar_integration_id}/calendars/{calendar_id}/events/{event_id}`.replace(`{${"tenant_id"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"calendar_integration_id"}}`, encodeURIComponent(String(requestParameters.calendarIntegrationId))).replace(`{${"calendar_id"}}`, encodeURIComponent(String(requestParameters.calendarId))).replace(`{${"event_id"}}`, encodeURIComponent(String(requestParameters.eventId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a calendar event
     * Delete a calendar event
     */
    async deleteEvent(requestParameters: DeleteEventRequest): Promise<void> {
        await this.deleteEventRaw(requestParameters);
    }

    /**
     * Extend a calendar event
     * Extend a calendar event
     */
    async extendCalendarEventRaw(requestParameters: ExtendCalendarEventRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling extendCalendarEvent.');
        }

        if (requestParameters.calendarIntegrationId === null || requestParameters.calendarIntegrationId === undefined) {
            throw new runtime.RequiredError('calendarIntegrationId','Required parameter requestParameters.calendarIntegrationId was null or undefined when calling extendCalendarEvent.');
        }

        if (requestParameters.calendarId === null || requestParameters.calendarId === undefined) {
            throw new runtime.RequiredError('calendarId','Required parameter requestParameters.calendarId was null or undefined when calling extendCalendarEvent.');
        }

        if (requestParameters.eventId === null || requestParameters.eventId === undefined) {
            throw new runtime.RequiredError('eventId','Required parameter requestParameters.eventId was null or undefined when calling extendCalendarEvent.');
        }

        if (requestParameters.extendEventRequest === null || requestParameters.extendEventRequest === undefined) {
            throw new runtime.RequiredError('extendEventRequest','Required parameter requestParameters.extendEventRequest was null or undefined when calling extendCalendarEvent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/{tenant_id}/calendar_integrations/{calendar_integration_id}/calendars/{calendar_id}/events/{event_id}/extend`.replace(`{${"tenant_id"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"calendar_integration_id"}}`, encodeURIComponent(String(requestParameters.calendarIntegrationId))).replace(`{${"calendar_id"}}`, encodeURIComponent(String(requestParameters.calendarId))).replace(`{${"event_id"}}`, encodeURIComponent(String(requestParameters.eventId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExtendEventRequestToJSON(requestParameters.extendEventRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Extend a calendar event
     * Extend a calendar event
     */
    async extendCalendarEvent(requestParameters: ExtendCalendarEventRequest): Promise<void> {
        await this.extendCalendarEventRaw(requestParameters);
    }

    /**
     * Get events of a tenant on a specific timerange
     * Get events of a tenant on a specific timerange
     */
    async getTenantEventsRaw(requestParameters: GetTenantEventsRequest): Promise<runtime.ApiResponse<Array<FacilityEvent>>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getTenantEvents.');
        }

        if (requestParameters.startTime === null || requestParameters.startTime === undefined) {
            throw new runtime.RequiredError('startTime','Required parameter requestParameters.startTime was null or undefined when calling getTenantEvents.');
        }

        if (requestParameters.endTime === null || requestParameters.endTime === undefined) {
            throw new runtime.RequiredError('endTime','Required parameter requestParameters.endTime was null or undefined when calling getTenantEvents.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startTime !== undefined) {
            queryParameters['start_time'] = (requestParameters.startTime as any).toISOString();
        }

        if (requestParameters.endTime !== undefined) {
            queryParameters['end_time'] = (requestParameters.endTime as any).toISOString();
        }

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.activeFacilityOnly !== undefined) {
            queryParameters['active_facility_only'] = requestParameters.activeFacilityOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/{tenant_id}/events`.replace(`{${"tenant_id"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FacilityEventFromJSON));
    }

    /**
     * Get events of a tenant on a specific timerange
     * Get events of a tenant on a specific timerange
     */
    async getTenantEvents(requestParameters: GetTenantEventsRequest): Promise<Array<FacilityEvent>> {
        const response = await this.getTenantEventsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create an instant event if it is possible
     * Create instant event
     */
    async postEventRaw(requestParameters: PostEventRequest): Promise<runtime.ApiResponse<Event>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling postEvent.');
        }

        if (requestParameters.calendarIntegrationId === null || requestParameters.calendarIntegrationId === undefined) {
            throw new runtime.RequiredError('calendarIntegrationId','Required parameter requestParameters.calendarIntegrationId was null or undefined when calling postEvent.');
        }

        if (requestParameters.calendarId === null || requestParameters.calendarId === undefined) {
            throw new runtime.RequiredError('calendarId','Required parameter requestParameters.calendarId was null or undefined when calling postEvent.');
        }

        if (requestParameters.createEventRequest === null || requestParameters.createEventRequest === undefined) {
            throw new runtime.RequiredError('createEventRequest','Required parameter requestParameters.createEventRequest was null or undefined when calling postEvent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/{tenant_id}/calendar_integrations/{calendar_integration_id}/calendars/{calendar_id}/events`.replace(`{${"tenant_id"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"calendar_integration_id"}}`, encodeURIComponent(String(requestParameters.calendarIntegrationId))).replace(`{${"calendar_id"}}`, encodeURIComponent(String(requestParameters.calendarId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEventRequestToJSON(requestParameters.createEventRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EventFromJSON(jsonValue));
    }

    /**
     * Create an instant event if it is possible
     * Create instant event
     */
    async postEvent(requestParameters: PostEventRequest): Promise<Event> {
        const response = await this.postEventRaw(requestParameters);
        return await response.value();
    }

    /**
     * Shorten a calendar event
     * Shorten a calendar event
     */
    async shortenCalendarEventRaw(requestParameters: ShortenCalendarEventRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling shortenCalendarEvent.');
        }

        if (requestParameters.calendarIntegrationId === null || requestParameters.calendarIntegrationId === undefined) {
            throw new runtime.RequiredError('calendarIntegrationId','Required parameter requestParameters.calendarIntegrationId was null or undefined when calling shortenCalendarEvent.');
        }

        if (requestParameters.calendarId === null || requestParameters.calendarId === undefined) {
            throw new runtime.RequiredError('calendarId','Required parameter requestParameters.calendarId was null or undefined when calling shortenCalendarEvent.');
        }

        if (requestParameters.eventId === null || requestParameters.eventId === undefined) {
            throw new runtime.RequiredError('eventId','Required parameter requestParameters.eventId was null or undefined when calling shortenCalendarEvent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/{tenant_id}/calendar_integrations/{calendar_integration_id}/calendars/{calendar_id}/events/{event_id}/shorten`.replace(`{${"tenant_id"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"calendar_integration_id"}}`, encodeURIComponent(String(requestParameters.calendarIntegrationId))).replace(`{${"calendar_id"}}`, encodeURIComponent(String(requestParameters.calendarId))).replace(`{${"event_id"}}`, encodeURIComponent(String(requestParameters.eventId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Shorten a calendar event
     * Shorten a calendar event
     */
    async shortenCalendarEvent(requestParameters: ShortenCalendarEventRequest): Promise<void> {
        await this.shortenCalendarEventRaw(requestParameters);
    }

}
