import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import CloseIcon from '@/assets/images/icon-close.svg';
import Arrow from '@/assets/images/arrow-thin.svg';

@Component({
  components: { Arrow, CloseIcon },
})
export default class Drawer extends Vue {
  @Prop({ type: Boolean }) show!: boolean;
}
