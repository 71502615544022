import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { differenceInMinutes } from 'date-fns';
import { UiFacility, FacilityStatusNameEnum } from '@/models/facility';
import FacilityStatusInfo from '@/components/organisms/FacilityStatusInfo.vue';
import TimelineDrawer from '@/components/organisms/TimelineDrawer.vue';
import FacilityReleaseSwiper from '@/components/atoms/FacilityReleaseSwiper.vue';
import FacilityBookButtonGroup from '@/components/molecules/FacilityBookButtonGroup.vue';
import FacilityBookConfirmation from '@/components/organisms/FacilityBookConfirmation.vue';

const facilityView = namespace('facilityView');

@Component({
  components: {
    FacilityStatusInfo,
    FacilityReleaseSwiper,
    FacilityBookButtonGroup,
    FacilityBookConfirmation,
    TimelineDrawer
  },
})
export default class FacilityDashboardLoaded extends Vue {
  @Prop({ type: Date }) now!: Date;

  @facilityView.State('facility') facility!: UiFacility;

  private showAdhocBookingConfirmation = false;

  private adhocBookingDuration = 0;

  private allowReleaseWaitMiniutes = 2;

  get isAvailable(): boolean {
    return (
      this.facility.status_name === FacilityStatusNameEnum.Vacant ||
      this.facility.status_name === FacilityStatusNameEnum.NoReservation
    );
  }

  get eventHasStartedLongEnough(): boolean{
    return !this.isAvailable && !!this.facility.event?.start_time && differenceInMinutes(this.now, this.facility.event.start_time) >= this.allowReleaseWaitMiniutes;
  }

  bookingHandler(min: number): void {
    this.adhocBookingDuration = min;
    this.showAdhocBookingConfirmation = true;
  }

  closeHandler(): void {
    this.showAdhocBookingConfirmation = false;
  }
}
