import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop } from 'vue-property-decorator';
import { UiFacility } from '@/models/facility';
import FacilityHelper, { isCustomFacility } from '@/helpers/facility';
import EventHelper from '@/helpers/event';

const facilityView = namespace('facilityView');

@Component
export default class FacilityBookButtonGroup extends Vue {
  @Prop({ type: Date }) now!: Date;

  @Prop({ type: Boolean }) disabled!: boolean;

  @facilityView.State('facility') facility!: UiFacility;

  private bookingOptions = [15, 30, 60];

  get facilityHelper(): FacilityHelper {
    return new FacilityHelper(this);
  }

  get eventHelper(): EventHelper {
    return new EventHelper(this);
  }

  get isOptionDisabled(): Record<string, boolean> {
    return this.bookingOptions.reduce(
      (acc, minutes) => {
        acc[minutes] =
          this.disabled ||
          !this.facilityHelper.isAvailable(this.facility) ||
          this.isOptionBlocked(minutes) ||
          isCustomFacility(this.facility);
        return acc;
      },
      {} as Record<string, boolean>,
    );
  }

  isOptionBlocked(minutes: number): boolean {
    return this.eventHelper.nextEventInMinutes(this.facility, this.now) < minutes;
  }
}
