import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Event } from '@/api/models';
import { formatDate } from '@/date';
import OrganizerIcon from '@/assets/images/icon-organizer.svg';
import ComingUpLabel from '@/components/atoms/ComingUpLabel.vue';
import Marquee from '@/components/atoms/Marquee.vue';

@Component({
  components: {
    OrganizerIcon,
    ComingUpLabel,
    Marquee
  }
})
export default class FacilityStatusEvent extends Vue {
  @Prop({ type: Object }) event!: Event;

  @Prop({ type: Boolean }) isComingUp!: boolean;

  @Prop({ type: Boolean }) isOccupied!: boolean;

  get startTime(): string {
    return formatDate(new Date(this.event.start_time), 'HH:mm');
  }

  get endTime(): string {
    return formatDate(new Date(this.event.end_time), 'HH:mm');
  }
}
