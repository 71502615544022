import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FacilityView } from '@/store/types/modules';
import { UiFacility } from '@/models/facility';
import { Event } from '@/api';
import Drawer from '@/components/templates/Drawer.vue';
import FacilityTimeline from '@/components/organisms/FacilityTimeline.vue';
import Loading from '@/components/atoms/Loading.vue';
import { isToday, formatDate } from '@/date';
import FacilityHelper from '@/helpers/facility';
import TenantHelper from '@/helpers/tenant';

const facilityView = namespace('facilityView');

@Component({
  components: { Loading, Drawer, FacilityTimeline },
})
export default class TimelineDrawer extends Vue {
  @Prop({ type: Date }) now!: Date;

  @facilityView.State('facility') facility!: UiFacility;

  @facilityView.State('cachedShortenedEvents') cachedShortenedEvents!: Event [];

  @facilityView.Action('setTimeline') setTimeline!: (isActive: boolean) => void;

  @facilityView.State('isTimelineActive') isTimelineActive!: FacilityView;

  isLoading = true;

  events: Event[] = [];

  get facilityHelper(): FacilityHelper {
    return new FacilityHelper(this);
  }

  get tenantHelper(): TenantHelper {
    return new TenantHelper(this);
  }

  get tenantId(): string {
    return this.tenantHelper.currentTenantId();
  }

  get timeNow(): string {
    return formatDate(this.now, 'HH:mm');
  }

  get startTime(): any {
    const today = new Date();
    today.setHours(0);
    today.setUTCMinutes(0);
    today.setUTCMinutes(0);

    return today;
  }

  get endTime(): Date {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0);
    tomorrow.setUTCMinutes(0);

    return tomorrow;
  }

  get sortedEvents(): Event[] {
    const sortedEvents = this.events.concat().sort(this.compare);
    sortedEvents.forEach(sortedEvt => {
      const cachedEvt = this.cachedShortenedEvents.find(e => (sortedEvt.id === e.id));
      if (cachedEvt) {
      // if an event has been shortened
        sortedEvt.updated = cachedEvt.updated;
        sortedEvt.end_time = cachedEvt.end_time;
      }
    });
    return sortedEvents;
  }

  get displayEvents(): Event[] {
    let currentOrNextEventIndex = 0;
    let right = this.sortedEvents.length - 1;
    let mid = 0;
    // if there's a current/next event today, get all events starting from one event previous to the current/next event today.
    const evt =
      this.facility.event ||
      (this.facility.next_event &&
        isToday(this.facility.next_event.start_time.toDateString()) &&
        this.facility.next_event);

    if (evt) {
      const len = this.sortedEvents.length;
      if (!len) {
        return [evt];
      }

      while (currentOrNextEventIndex < right) {
        mid = Math.floor((currentOrNextEventIndex + right) / 2);
        if (this.sortedEvents[mid].end_time < evt.end_time) {
          currentOrNextEventIndex = mid + 1;
        } else {
          right = mid;
        }
      }

      const displayEvents = this.sortedEvents.concat();
      if (this.sortedEvents[currentOrNextEventIndex].id !== evt.id) {
        // if the current event is a cached event
        if (currentOrNextEventIndex === len - 1 && this.sortedEvents[len-1].end_time < evt.end_time) {
          // the day has finshed all the events so the cachced event is the last event
          return [displayEvents[len-1], evt];
        } 
        displayEvents.splice(currentOrNextEventIndex, 0, evt);
      }
      
      if (currentOrNextEventIndex > 0) {
        return displayEvents.slice(currentOrNextEventIndex - 1);
      }
      return displayEvents;
    }
    // All events have finished, so just show the last event
    return this.sortedEvents.slice(this.sortedEvents.length - 1);
  }

  async created(): Promise<void> {
    await this.fetchEvents();
    this.isLoading = false;
  }

  @Watch('now')
  async fetchEvents(): Promise<void> {
    try {
      this.events = await this.facilityHelper.fetchFacilityEvents(
        this.tenantId,
        this.facility.facility_id,
        this.startTime,
        this.endTime,
      );
    } catch (e) {
      console.error('Error fetching events: ', e);
    }
  }

  compare(eventA: Event, eventB: Event): number {
    const starTimeA = eventA.start_time;
    const starTimeB = eventB.start_time;

    let comparison = 0;
    if (starTimeA > starTimeB) {
      comparison = 1;
    } else if (starTimeA < starTimeB) {
      comparison = -1;
    }
    return comparison;
  }

  @Watch('isTimelineActive')
  resetTimelineScrolltop(): void {
    const drawerBody = this.$refs.drawerBody as Vue;
    if (!this.isTimelineActive) {
      if (drawerBody.$el.parentElement) {
        drawerBody.$el.parentElement.scrollTop = 0;
      }
    }
  }
}
