import Vue from 'vue';
import Component from 'vue-class-component';
import { State, namespace } from 'vuex-class';
import { differenceInMinutes } from 'date-fns';
import { Route, NavigationGuardNext } from 'vue-router';
import Loading from '@/components/atoms/Loading.vue';
import FacilityDashboardLoaded from '@/components/templates/FacilityDashboardLoaded.vue';
import { UiFacility } from '@/models/facility';
import { Event } from '@/api/models';
import FacilityHelper from '@/helpers/facility';
import { TenantDetails, BookedFacility } from '@/store/types/modules';
import logger from '@/logger';
import { API_CACHE_MINUTES } from '@/env';
import store from '@/store';

const facilityView = namespace('facilityView');

@Component({ components: { Loading, FacilityDashboardLoaded } })
export default class FacilityDashboard extends Vue {
  private error = false;

  private errMessage = '';

  private periodicFetch = 0;

  private fetchedFacility!: UiFacility;

  private now = new Date();

  @State('tenant') tenant!: TenantDetails;

  @State('bookedFacility') bookedFacilityState!: BookedFacility;
  
  @facilityView.State('facility') facility!: UiFacility;

  @facilityView.State('cachedFacility') cachedFacility!: UiFacility;

  @facilityView.State('cachedShortenedEvents') cachedShortenedEvents!: Event [];

  @facilityView.Mutation('setFacility') setFacility!: (f: UiFacility) => void;

  @facilityView.Mutation('resetCachedFacility') resetCachedFacility!: () => void;

  @facilityView.Mutation('removeExpiredShortenedEvents') removeExpiredShortenedEvents!: () => void;

  get facilityHelper(): FacilityHelper {
    return new FacilityHelper(this);
  }

  async created(): Promise<void> {
    // TODO: validate route query
    // 1. check access token
    if (!(await this.validFacilityId())) {
      this.error = true;
    }
    await this.initFacility();
    this.setFacility(this.fetchedFacility);

    this.setFetchInterval();
  }

  async validFacilityId(): Promise<boolean> {
    const facilityId = this.$route.query.facility_id;
    if (!facilityId) {
      this.errMessage = this.$gettext("Sorry, we can't find the facility, please check whether the URL is correct or not.");
      return false;
    }
    return true;
  }

  isBookedRecently(): boolean {
    if (!this.cachedFacility || !this.cachedFacility.event || !this.cachedFacility.event.updated) {
      return false;
    }
    const now = new Date();
    const eventDate = new Date(this.cachedFacility.event.updated);
    return differenceInMinutes(now, eventDate) <= API_CACHE_MINUTES;
  }

  isShortenedRecently(): boolean {
    if (this.cachedFacility && !this.cachedFacility.event) {
      const now = new Date();
      const recentlyShortenedEvents = this.cachedShortenedEvents.filter( event => {
        if (!event.updated) {
          return false;
        }
        return differenceInMinutes(now, new Date(event.updated)) <= API_CACHE_MINUTES;
      });

      if (recentlyShortenedEvents.length) {
        return true;
      }
    }
    return false;
  }

  setFetchInterval(): void {
    const nextTick = 60000 - (new Date().getTime() % 60000);
    this.now = new Date();

    this.periodicFetch = window.setInterval(async () => {
      await this.initFacility();
      this.refreshInterval();
    }, nextTick);
  }

  refreshInterval(): void {
    clearInterval(this.periodicFetch);
    this.setFetchInterval();
  }

  async initFacility(): Promise<void> {
    await this.fetchFacility();
    if (this.isBookedRecently() || this.isShortenedRecently()) {
      this.setFacility(this.cachedFacility);
    } else
    {
      this.setFacility(this.fetchedFacility);
      this.resetCachedFacility();
    }

    this.removeExpiredShortenedEvents();
  }
  
  async fetchFacility(): Promise<void> {
    if (typeof this.$route.query.facility_id !== 'string') {
      return;
    }

    try {
      this.fetchedFacility = await this.facilityHelper.fetchFacility(
        this.tenant.current.id,
        this.$route.query.facility_id,
      );
    } catch(e) {
      this.errMessage = this.$gettext("We can't find any facility matching your request, please check whether the URL is correct or not.");
      logger.error(this.$gettext("We can't find any facility matching your request, please check whether the URL is correct or not."));
      this.error = true;
    }
  }

  beforeRouteEnter(to: Route, from: Route , next: NavigationGuardNext): void {
    store.commit('facilityView/resetCachedFacility');
    store.commit('facilityView/resetCachedShortenedEvents');
    next();
  };
}
