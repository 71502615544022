import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Hammer from 'hammerjs';
import { State, namespace } from 'vuex-class';
import { cloneDeep } from 'lodash';
import { UiFacility, FacilityStatusNameEnum } from '@/models/facility';
import { Event } from '@/api/models';
import logger from '@/logger';
import EventHelper from '@/helpers/event';
import { TenantDetails } from '@/store/types/modules';
import FacilityReleaseIcon from '@/assets/images/icon-release-room.svg';


const facilityView = namespace('facilityView');

@Component({ components: { FacilityReleaseIcon } })
export default class FacilityReleaseSwiper extends Vue {

  @State('tenant') tenant!: TenantDetails;

  @facilityView.State('facility') facility!: UiFacility;

  @facilityView.Mutation('pushCachedShortenedEvents') pushCachedShortenedEvents!: (e: Event) => void;

  @facilityView.Mutation('setCachedFacility') setCachedFacility!: (f: UiFacility) => void;

  @facilityView.Mutation('setFacility') setFacility!: (f: UiFacility) => void;

  @Prop({ type: Date }) now!: Date;

  private mc: HammerManager | undefined = undefined;

  private lastPosX = 0;

  private posX = 40;

  private minPosX = 40;

  private elem: HTMLElement|undefined = undefined;

  get eventHelper(): EventHelper {
    return new EventHelper(this);
  }

  get currentTenantID(): string {
    return this.tenant.current.id;
  }
  
  get maxPosX (): number {
    return window.innerWidth - this.minPosX;
  }

  mounted(): void {
    const swiper = this.$refs.swiper as HTMLElement;
    this.mc = new Hammer(swiper);
    this.mc.add( new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 0 }) );
    this.mc.on('pan', this.handleDrag);
  }

  handleDrag(ev: any): void {
    this.elem = ev.target;
    
    if (this.elem) {
      this.lastPosX = this.elem.offsetLeft;

      this.posX = ev.deltaX + this.lastPosX;
    
      if (ev.isFinal) {
        this.onTouchEnd();
      }
    }
  }

  resetPosition(): void {
    this.posX = this.minPosX;
  }

  onTouchEnd(): void {
    if (this.posX < this.maxPosX) {
      this.resetPosition();
    } else {
      this.shortenCurrentEvent();
    }
  }

  shortenCurrentEvent(): void {
    if (!this.currentTenantID) {
      logger.warn(`Event shortening operation is called by a user without a proper tenant Id.`);
    }

    if (!this.facility.event || !this.facility.event.id) {
      logger.warn(`Event shortening operation is called on a facility without a current event: ${this.facility}`);
      return;
    }

    if (!this.facility.calendar_id || !this.facility.calendar_integration_id) {
      logger.warn(`Event shortening operation is called on a custom facility`);
      return;
    }

    // the API call
    this.eventHelper.shortenFacilityEvent({
      tenantId: this.currentTenantID,
      calendarIntegrationId: this.facility.calendar_integration_id,
      calendarId: this.facility.calendar_id,
      eventId: this.facility.event.id
    });

    // add to cache
    const cachedFacility = cloneDeep(this.facility);
    cachedFacility.event = undefined;
    const cachedShortenedEvent = cloneDeep(this.facility.event);
    const time = this.now > cachedShortenedEvent.start_time ? this.now : cachedShortenedEvent.start_time;
    cachedShortenedEvent.end_time = time;
    cachedShortenedEvent.updated = time;
    this.pushCachedShortenedEvents(cachedShortenedEvent);

    cachedFacility.status_name = FacilityStatusNameEnum.Vacant;
    this.setCachedFacility(cachedFacility);
    this.setFacility(cachedFacility);
  }
}
