import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Event } from '@/api';
import { formatDate } from '@/date';
import ComingUpLabel from '@/components/atoms/ComingUpLabel.vue';

@Component({ components: { ComingUpLabel } })
export default class FacilityTimelineEvent extends Vue {
  @Prop({ type: Object }) event!: Event;

  @Prop({ type: Boolean, required: false, default: false }) isPastEvent!: boolean;

  @Prop({ type: Boolean, required: false, default: false }) isCurrentEvent!: boolean;

  @Prop({ type: Boolean, required: false, default: false }) isNextEvent!: boolean;

  @Prop({ type: Date }) now!: Date;

  get startTime(): string {
    return formatDate(this.event.start_time, 'HH:mm');
  }

  get endTime(): string {
    return formatDate(this.event.end_time, 'HH:mm');
  }
}
