import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import { Event } from '@/api';
import FacilityTimelineEvent from '@/components/molecules/FacilityTimelineEvent.vue';
import { UiFacility } from '@/models/facility';

const facilityView = namespace('facilityView');


@Component({
  components: { FacilityTimelineEvent },
})
export default class FacilityTimeline extends Vue {
  @Prop({ type: Array }) events!: Event[];

  @Prop({ type: Date }) now!: Date;

  @Prop({ type: String, required: false, default: undefined }) currentEventId!: string | undefined;

  @Prop({ type: String, required: false, default: undefined }) nextEventId!: string | undefined;

  @facilityView.State('cachedFacility') cachedFacility!: UiFacility;

  private animatedEvents: Event[] = [];

  private inTransition = false;

  // For testing the animation
  // private testEvents: Event[] = [];

  // private testCurrentEventId: string | undefined;

  // private testNextEventId: string | undefined;

  mounted(): void{
    this.syncAnimatedEvents();
    // For testing the animation
    // this.testEvents = cloneDeep(this.events);
    // this.testCurrentEventId = this.currentEventId;
    // this.testNextEventId = this.nextEventId;
    // setInterval(()=>{
    //   if (this.testCurrentEventId === this.currentEventId) {
    //     this.testEvents = cloneDeep(this.events.slice(1));
    //     this.testCurrentEventId = this.nextEventId;
    //     this.testNextEventId = this.events.length > 3 ? this.events[3].id : undefined;
    //   } else {
    //     this.testEvents = cloneDeep(this.events);
    //     this.testCurrentEventId = this.currentEventId;
    //     this.testNextEventId = this.nextEventId;
    //   }
    // }, 2000);
  }

  get firstEventId() : string | undefined {
    return this.events.length ? this.events[0].id : undefined;
  }

  @Watch('now')
  @Watch('cachedFacility.status_name')
  @Watch('firstEventId')
  syncAnimatedEvents(): void {
    if (!this.inTransition) {
      this.animatedEvents = cloneDeep(this.events);
    }
  }

  // For testing the animation
  // @Watch('testEvents.length')
  @Watch('currentEventId')
  onEventsChange(): void {
    // use the other if-statement for testing the animation
    // if (this.testEvents.length < this.events.length){
    if (this.animatedEvents.length < this.events.length){
      this.inTransition = true;
      setTimeout(()=>{
        this.inTransition = false;
        // comment the following out if you want to test
        this.animatedEvents = cloneDeep(this.events);
      }, 900);
    } else {
      this.animatedEvents = cloneDeep(this.events);
    }
    // Uncomment this block for testing the animation
    // setTimeout(()=>{
    //   this.animatedEvents = cloneDeep(this.testEvents);
    // }, 900);
  }
}
