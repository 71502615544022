import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop } from 'vue-property-decorator';
import { UiFacility, FacilityStatusNameEnum } from '@/models/facility';
import { Event } from '@/api/models';
import { isToday, formatDate } from '@/date';
import FacilityStatusEvent from '@/components/molecules/FacilityStatusEvent.vue';
import FacilityAvailableIcon from '@/assets/images/icon-facility-available.svg';
import FacilityNotUsingIcon from '@/assets/images/icon-facility-not-using.svg';
import FacilityUsingIcon from '@/assets/images/icon-facility-using.svg';
import FacilityGhostIcon from '@/assets/images/icon-facility-ghost.svg';
import WarningIcon from '@/assets/images/warning.svg';

const facilityView = namespace('facilityView');

@Component({ components: { 
  FacilityStatusEvent, 
  FacilityAvailableIcon,
  FacilityNotUsingIcon,
  FacilityUsingIcon,
  FacilityGhostIcon,
  WarningIcon
} })
export default class FacilityStatusInfo extends Vue {
  @Prop({ type: Date }) now!: Date;

  @facilityView.State('facility') facility!: UiFacility;

  get statusText(): string {
    switch (this.facility.status_name) {
      case FacilityStatusNameEnum.Vacant:
      case FacilityStatusNameEnum.NoReservation:
        return this.$gettext('Available');
      case FacilityStatusNameEnum.Occupied:
      case FacilityStatusNameEnum.NoAttendees:
        return this.$gettext('Booked');
      default:
        return this.$gettext('Unknown Status');
    }
  }

  get warningMsg(): string | undefined {
    switch (this.facility.status_name) {
      case FacilityStatusNameEnum.NoReservation:
        return this.$gettext('But there is somebody in it');
      case FacilityStatusNameEnum.NoAttendees:
        return this.$gettext('But no one is in it');
      default:
        return undefined;
    }
  }

  get facilityStatusIcon(): string {
    switch (this.facility.status_name) {
      case FacilityStatusNameEnum.Vacant:
        return 'facility-available-icon';
      case FacilityStatusNameEnum.NoReservation:
        return 'facility-ghost-icon';
      case FacilityStatusNameEnum.NoAttendees:
        return 'facility-not-using-icon';
      default:
        return 'facility-using-icon';
    }
  }

  get isFacilityOccupied(): boolean {
    return this.facility.status_name === FacilityStatusNameEnum.Occupied || this.facility.status_name === FacilityStatusNameEnum.NoAttendees;
  }

  get showUntilTime(): boolean {
    return this.facility.status_name !== FacilityStatusNameEnum.NoReservation;
  }

  get timeLeft(): string {
    let res: string;

    if (!this.facility.event) {
      if (this.facility.next_event && isToday(this.facility.next_event.start_time.toDateString())) {
        const eventStartTime = new Date(this.facility.next_event.start_time);
        const minutes = Math.abs(Math.round((eventStartTime.getTime() / 1000 - this.now.getTime() / 1000) / 60));
        res = this.convertAvailbaleMinutesToText(minutes);
      } else {
        res = this.$gettext('Until the end of the day');
      }
    } else {
      const eventEndTime = new Date(this.facility.event.end_time);
      const minutes = Math.abs(Math.round((eventEndTime.getTime() / 1000 - this.now.getTime() / 1000) / 60));
      res = this.convertBookedMinutesToText(minutes);
    }

    return res;
  }

  get timeNow(): string {
    return formatDate(this.now, 'HH:mm');
  }

  get eventShown(): Event | undefined {
    if (this.facility.event) {
      return this.facility.event;
    }
    if (this.facility.next_event && isToday(this.facility.next_event.start_time.toDateString())) {
      return this.facility.next_event;
    }
    return undefined;
  }

  convertHours(mins: number): number {
    return Math.floor(mins / 60);
  }

  convertMinutes(mins: number): number {
    return mins % 60;
  }

  convertBookedMinutesToText(mins: number): string {
    const h = this.convertHours(mins);
    const m = this.convertMinutes(mins);
    return h !== 0 ?
      this.$gettextInterpolate(this.$gettext('%{h} hours %{m} minutes left'), { h, m })
      : this.$gettextInterpolate(this.$gettext('%{m} minutes left'), { m });
  }

  convertAvailbaleMinutesToText(mins: number): string {
    const h = this.convertHours(mins);
    const m = this.convertMinutes(mins);
    return h !== 0 ?
      this.$gettextInterpolate(this.$gettext('For %{h} hours %{m} minutes'), { h, m })
      : this.$gettextInterpolate(this.$gettext('For %{m} minutes'), { m });
  }
}
